import DataTable from "components/DataTable";
// import useFetchRequest from "hooks/useFetchRequest";
// import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import MDTypography from "components/MDTypography";
import { useImmer } from "use-immer";
import _ from "lodash";
import { useEffect, useState } from "react";
import fetchRequest from "utils/fetchRequest";
// import { useAppController } from "context";
// import { normalizeCurrency } from "utils/table";

const AssetSpendView = (props) => {
    const { yearFilter, monthFilter, containerHeight, setAction, chargeBackMonthly, serverSideDataPaging } = props;
    const [ pgIndx, setPgIndx] = useState(serverSideDataPaging ? 0 : null)
    const [ pageSize, setPageSize] = useState(serverSideDataPaging ? 50 : null)
    const [ totalNoOfPages, setTotalNoOfPages ] = useState(0)
    const [ totalItems, setTotalItems ] = useState(null)
    // const [ search, setSearch ] = useState(null)
    const [ uploadedFiles, setUploadedFiles ] = useState(null)
    const [ rows, setRows ] = useState(null)
    const [ _err, setError ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    // const [ reload, setReload ] = useState(null)
    
    const [filtersState, setFiltersState] = useImmer({
        globalFilter: undefined,
        filters: [],
    });
    const chargeBack = _.find(chargeBackMonthly, { monthNameId: monthFilter, yearNameId: yearFilter }) ? true : false
    // const [controller] = useAppController();
    // const { appDef: { settings } } = controller;
    // const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState((draft) => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    };

    let handlePageOption = () => {
        onPageOptionsChange({ currentPage: 0, pageSize: pageSize })
    }

    const onPageOptionsChange = (pageOption) => {
        if (serverSideDataPaging) {
            setLoading(true) 
        }
        setPgIndx(pageOption.currentPage);
        setPageSize(pageOption.pageSize)
    }

    // const reloadData = () => setReload(Math.random())

    useEffect(async () => {
      if (!serverSideDataPaging) {
            setLoading(true)
            // const filters = { filters: null, searchString: search ? search?.trim() : null}
            let [err, data] = await fetchRequest.get(`/api/dataflow/capabilities/${yearFilter}/${monthFilter}`)
            if (err) {
                setError(err)
                setLoading(false)
            } else {
                setUploadedFiles(data)
                const buildRows = data.map((row) => {
                    let r = {};
                    row.id = `${row.id}`
                    Object.keys(row).forEach((k) => {
                        r[k.replace(/\./g, "__")] = row[k];
                    });
                    return r;
                });
                setRows(buildRows)
                setLoading(false)
            }
        }  
    },[yearFilter, monthFilter])

    useEffect(async () => {
        if (serverSideDataPaging) {
            setLoading(true)
            // const filters = { filters: null, searchString: search ? search?.trim() : null}
            const filters = { filters: null, searchString: null}
            let [err, response] = await fetchRequest.post(`/api/dataflow/capabilities/${yearFilter}/${monthFilter}?pgIndx=${pgIndx}&pageSize=${pageSize}`, filters)
            if (err) {
                setError(err)
                setLoading(false)
            } else {
                const { data, totalItems, totalPages }  = response
                setUploadedFiles(data)
                const buildRows = data.map((row) => {
                    let r = {};
                    row.id = `${row.id}`
                    Object.keys(row).forEach((k) => {
                        r[k.replace(/\./g, "__")] = row[k];
                    });
                    return r;
                });
                setRows(buildRows)
                setTotalItems(totalItems)
                setTotalNoOfPages(totalPages)
                setLoading(false)
            }
        }  
    },[yearFilter, monthFilter, pgIndx, pageSize])
    // const { response: uploadedFiles, error: _err, loading: loading } = useFetchRequest(`/api/dataflow/capabilities/${yearFilter}/${monthFilter}`)

    let columns = [
            { Header: "File", accessor: "originalFileName",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "From Asset", accessor: "fromAsset",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Code", accessor: "fromCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "To Asset", accessor: "toAsset",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Code", accessor: "toCode",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Portion", accessor: "portion",dataType: "textbox", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            // { Header: "From Amount", accessor: "amount",dataType: "currency",align:"right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0.00')}</MDTypography> } },
            // { Header: "To Amount", accessor: "spend",dataType: "currency",align:"right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral(normalizeCurrency(value)).format('$0,0.00')}</MDTypography> } },
    ];
            
    if (loading) return <YASkeleton variant="loading" />;

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                {_err}
                no data
            </div>
        );
    }

    const handleAction = () => {
        setAction('addAssetMapping')
    }

    const getMenuActions = () => {
        let actions = [];
        actions.push({ label: "Add Mapping", onClick: handleAction, disabled: chargeBack })
        return actions;
    }
    
    return (
        <DataTable
            variant="tile"
            table={{ columns, rows }}
            containerMaxHeight={containerHeight}
            showTotalEntries={true}
            newStyle1={true}
            isSorted={true}
            noEndBorder
            entriesPerPage={true}
            menuActionRender={getMenuActions()}
            canSearch={true}
            filtersState={filtersState}
            onFiltersStateUpdate={handleOnFiltersStateUpdate}
            canFilter={true}
            viewTable={true}
            serverSideDataPaging={serverSideDataPaging}
            pgIndx={pgIndx}
            setPgIndx={setPgIndx}
            onPageOptionsChange={onPageOptionsChange}
            pageSizeVal={pageSize}
            totalNoOfPages={totalNoOfPages}
            totalItems={totalItems}
            // setSrch={setSearch}
            // srch={search}
            handlePageOption={handlePageOption}
        />
    );
};

export default AssetSpendView;
