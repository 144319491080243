import _ from "lodash";
import { Icon, IconButton, Tooltip, CircularProgress } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "components/DataTable";
// import useFetchRequest from "hooks/useFetchRequest";
import numeral from "numeral";
import YASkeleton from "components/YASkeleton";
import fetchRequest from "utils/fetchRequest";
import { useYADialog } from "components/YADialog";
import moment from "moment";
import MDAvatar from "components/MDAvatar";
import { useAppController } from "context";
import { useImmer } from "use-immer";
import { useState, useEffect, useContext } from "react";
import MDBox from "components/MDBox";
import { normalizeCurrency } from "utils/table";
import { useNotificationSubscription } from "hooks/useNotificationManager";
import { NotificationType } from "constants";
import MDButton from 'components/MDButton';
import { YADialogCustomFormContext } from "components/YADialogCustomForm";
import DraggableDataTable from 'components/DraggableDataTable';
import { formatCurrencyNumeral } from "utils";


const GetSortPopup = (props) => {
    const { columns, rows, reloadData, yearFilter, monthFilter, destinationTable, maxWidth } = props
    const [data, setData] = useState([]);
    const [ isSubmitting, setIsSubmitting ] = useState(false)
    const { onDialogClose } = useContext(YADialogCustomFormContext);
    const { showAlert, showSnackbar } = useYADialog();
  
    const onApplyRules = async() => {
        setIsSubmitting(true)
        const sortSequence = rows.map(item => {
            return {id: item.id, sequence: data.find(i => i.priority === item.priority)?.sequence, priority: item.priority}
        })
        let [err, response] = await fetchRequest.post(`/api/dataflow/rules/${destinationTable}/${yearFilter}/${monthFilter}/applySort`, {data: sortSequence});
        if (err) {
            if (err.data?.message) {
                setIsSubmitting(false)
                onDialogClose();
                reloadData();
                showSnackbar(err.data?.message, "error")
            } else {
                setIsSubmitting(false)
                showAlert("Apply Sort", "Something went wrong. Contact your administrator.");
            }
        } else {
            setIsSubmitting(false)
            onDialogClose();
            reloadData();
            showSnackbar(response.message, "success");
        }
    }
  
    const applyBtnRenderer = () => {
        return (
          <MDButton 
              startIcon={isSubmitting ? <CircularProgress color="white" size={15} /> : undefined} 
              disabled={isSubmitting || data.length === 0} 
              onClick={() => onApplyRules()} 
              color={ "info"} 
              sx={{mr:5}} 
              variant="contained"
          >
              Apply Sort
          </MDButton>
        );
    }
    // const filteredRows = rows.filter((item) => item.srlNo === 1)
    return (
      <MDBox  pb={3}
      minWidth="800px"
      width="100%"
      >
        <DraggableDataTable
            primaryRender={applyBtnRenderer()}
            subTitle={"Sort the rules by drag and drop"}
            table={{ columns, rows: rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            canSearch={true}
            sortedData={setData}
            sortColumn={"sequence"}
            maxWidth={maxWidth}
            actionButton={true}
        />
    </MDBox>
    )
}

const TowerMappingView = (props) => {
    const { showAlert, showPrompt, showSnackbar, showCustomForm } = useYADialog();
    const { yearFilter, yearFilterName, monthFilter, monthFilterName, id, ruleName, containerHeight, typeFilter, overallocation, setRefresh, chargeBackMonthly, setAction, download, popup, setEdit, setEditValue, serverSideDataPaging } = props;
    const [controller,] = useAppController();
    const { appDef: { featureSet, settings }, systemCurrencyDetails } = controller;
    const enableAssetDistribution = featureSet && featureSet.dataManagement?.assetDistribution === true;
    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const [filtersState, setFiltersState] = useImmer({ globalFilter: undefined, filters: [] });
    const [ pgIndx, setPgIndx] = useState( serverSideDataPaging ? 0 : null)
    const [ pageSize, setPageSize] = useState( serverSideDataPaging ? 50 : null)
    const [ totalNoOfPages, setTotalNoOfPages ] = useState(0)
    const [ totalItems, setTotalItems ] = useState(null)
    // const [ search, setSearch ] = useState(null)
    const [ uploadedFiles, setUploadedFiles ] = useState(null)
    const [ _err, setError ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ reload, setReload ] = useState(null)
    const handleOnFiltersStateUpdate = (latestGlobalFilter, latestFilters) => {
        setFiltersState(draft => {
            draft.globalFilter = latestGlobalFilter;
            draft.filters = latestFilters;
        });
    }
    const chargeBack = _.find(chargeBackMonthly, { monthNameId: monthFilter, yearNameId: yearFilter }) ? true : false;

    let handlePageOption = () => {
        onPageOptionsChange({ currentPage: 0, pageSize: pageSize })
    }

    const onPageOptionsChange = (pageOption) => {
        if (serverSideDataPaging) {
            setLoading(true) 
        }
        setPgIndx(pageOption.currentPage);
        setPageSize(pageOption.pageSize)
    }

    const reloadData = () => setReload(Math.random())

    useEffect(async() => {
        if (!serverSideDataPaging) {
            setLoading(true)
            // const filters = { filters: null, searchString: search ? search?.trim() : null}
            const filters = { filters: null, searchString: null }
            let [err, data] = typeFilter === "Budget" || typeFilter == "Forecast" ? await fetchRequest.get(`/api/dataflow/towerRulesBudget/${yearFilter}/${monthFilter}?allocated=${true}&&type=${typeFilter.toLowerCase()}`, filters) :
            (id) ? await fetchRequest.get(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?towerId=${id}`, filters)
                : ((ruleName) ? await fetchRequest.get(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?ruleName=${encodeURIComponent(ruleName)}`, filters)
                    : overallocation ? await fetchRequest.get(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?overallocation=${overallocation}`, filters) : await fetchRequest.get(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?`, filters))
            if (err) {
                setError(err)
                setLoading(false)
            } else {
                setUploadedFiles(data)
                setLoading(false)
            }
        }
    },[reload])

    useEffect(async() => {
        if (serverSideDataPaging) {
            setLoading(true)
            // const filters = { filters: null, searchString: search ? search?.trim() : null}
            const filters = { filters: null, searchString: null }
            let [err, response] = typeFilter === "Budget" || typeFilter == "Forecast" ? await fetchRequest.post(`/api/dataflow/towerRulesBudget/${yearFilter}/${monthFilter}?allocated=${true}&&type=${typeFilter.toLowerCase()}&pgIndx=${pgIndx}&pageSize=${pageSize}`, filters) :
            (id) ? await fetchRequest.post(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?towerId=${id}&pgIndx=${pgIndx}&pageSize=${pageSize}`, filters)
                : ((ruleName) ? await fetchRequest.post(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?ruleName=${ruleName}&pgIndx=${pgIndx}&pageSize=${pageSize}`, filters)
                    : overallocation ? await fetchRequest.post(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?overallocation=${overallocation}&pgIndx=${pgIndx}&pageSize=${pageSize}`, filters) : await fetchRequest.post(`/api/dataflow/towerMapping/${yearFilter}/${monthFilter}?&pgIndx=${pgIndx}&pageSize=${pageSize}`, filters))
            if (err) {
                setError(err)
                setLoading(false)
            } else {
                const { data, totalItems, totalPages }  = response
                setUploadedFiles(data)
                setTotalItems(totalItems)
                setTotalNoOfPages(totalPages)
                setLoading(false)
            }
        }
    },[pageSize, pgIndx, reload])

    const handleDelete = (item) => {
        showPrompt("Delete", "Are you sure you want to delete - [" + item["tower__name"] + " (" + item["subTower__name"] + ") with Priority "+ item["priority"] +"]", async () => {
            let [err, data] = await fetchRequest.delete(`/api/dataflow/towerMapping/${item["id"]}`)
            if (err) {
                console.log(err);
                if (err.data?.message) {
                    showSnackbar(err.data?.message, "error")
                    setRefresh(Math.random());
                    reloadData()
                } else {
                    showAlert("Delete", "Something went wrong. Contact your administrator.");
                }
            }
            else if (data) {
                showSnackbar(data, "success")
                if (setRefresh) {
                    setRefresh(Math.random())
                }
                reloadData()
            }
        });
    }

    const handleEdit = (item) => {
        showPrompt(
            "Edit",
            `Are you sure you want to edit [${item["tower__name"]} (${item["subTower__name"]}) with Priority ${item["priority"]}]`,
            async () => {
                const filterCondition = JSON.parse(item.filterCondition).map( cond => {
                    if(String(cond.tableName).endsWith('s')) {
                        cond.tableName = String(cond.tableName).slice(0, cond.tableName.length - 1)
                    }
                    return cond
                })
                setEdit(true)
                setEditValue({
                    mappingId: item.id,
                    filterCondition: JSON.stringify(filterCondition),
                    condition: item.condition,
                    portion: item.portion,
                    weight: item.weight,
                    tier: item.tier,
                    priority: item.priority,
                    allocationFileId: item.allocationFileId,
                    destinationTable: item.destinationTable,
                    ruleName: item.ruleName,
                    tower: `${item.towerId} | ${item.subTowerId}`,
                    assetLink: item['original_assetLink']
                })
                setAction('add')
            }
        );
    };

    useNotificationSubscription(NotificationType.ProcessFile, (notification) => {
        if (notification.details?.destinationTable && notification.details?.destinationTable === "towerMapping") {
            reloadData();
        }
    });

    const handleDeleteMultiple = (selectedRows) => {
        const twIdsFromCUR = uploadedFiles.map(i => i.source === 'cloud' ? i.id : null)
        _.pullAll(selectedRows, _.compact(twIdsFromCUR))
        showPrompt('Delete', 'Are you sure you want to delete?', () => deleteTowerMappingMultipleRecords(JSON.stringify(selectedRows)));
    };

    const deleteTowerMappingMultipleRecords = async (selectedRows) => {
        const [err, data] = await fetchRequest.post(`/api/dataflow/towerMapping`, selectedRows);

        if (err) {
            if (err.data?.message) {
                showSnackbar(err.data?.message, "error")
                setRefresh(Math.random());
                reloadData()
            } else {
                showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
        }
        else
            if (data) {
                showSnackbar(data, "success")
                if (setRefresh) {
                    setRefresh(Math.random())
                }
                reloadData()
            }
    }

    let columns = []
    if (typeFilter === 'Spend')
        columns = [
            { 
                Header: "Actions", 
                disableSorting: true, 
                disableFilters: true, 
                // hidden: chargeBack, 
                accessor: "id", 
                maxWidth: 90, 
                Cell: ({ row: { original } }) => { 
                    return (
                        <>
                        {
                            <Tooltip title={ chargeBack ? "Chargeback Created" : "Edit"} placement="top">
                            <IconButton 
                            sx={{ padding: 0 }} 
                            onClick={(e) => {e.stopPropagation(); original?.source === 'cloud' || original?.portion !== 1 || original?.ruleName ? null : !chargeBack && handleEdit(original)}}
                            >
                                <Icon color={original?.source === 'cloud' || original?.portion !== 1 || original?.ruleName || chargeBack ? 'disabled' : "info"} >edit</Icon>
                            </IconButton>
                            </Tooltip>
                        }
                        {
                            <Tooltip title={ chargeBack ? "Chargeback Created" : "Delete"} placement="top">
                            <IconButton sx={{ padding: 0 }} onClick={(e) => {e.stopPropagation(); original?.source === 'cloud' ? null : !chargeBack && handleDelete(original)}}>
                            <Icon color={original?.source === 'cloud' || chargeBack ? 'disabled' : "error"}>delete</Icon>
                            </IconButton>
                            </Tooltip>
                        }
                            
                        </>
                    )
                } 
            },
            {
                Header: "Priority",
                accessor: "priority",
                dataType: "textbox",
                disableFilters: false,
                Cell: ({ cell: { value } }) => {
                    return (
                        <MDTypography variant="caption" color="dark">
                            {value}
                        </MDTypography>
                    );
                },
            },
            { Header: "Filter", accessor: "filterCondition", disableFilters: true, Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><Icon sx={{ px: 0, m: 0 }} fontSize="small" color="text">info</Icon></Tooltip> : null } },
            { Header: "Tower", accessor: "tower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: "Sub Tower", accessor: "subTower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: "Amount", accessor: "exAmount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
            { Header: "Portion", accessor: "portion", disableFilters: true, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral((value) * 100.0).format('0.00')}%</MDTypography> } },
            { Header: "GL Amount", accessor: "glAmount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
            { Header: "Rule Name", accessor: "ruleName", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: "Tier", accessor: "tier", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: "Condition", accessor: "condtionstr", disableFilters: true, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><Icon sx={{ px: 0, m: 0 }} fontSize="small" color="text">info</Icon></Tooltip> : null } },
            { Header: "Destination", accessor: "destinationTable", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Weight Field", accessor: "weight", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Asset ID Field (GL)", accessor: "assetLink", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Source", accessor: "source", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return (<MDTypography variant="caption" color="dark">{value}</MDTypography>) } },
            { Header: "File", accessor: "uploadedFileTowerMapping__originalFileName", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Uploaded On", accessor: "createdAt", disableFilters: true, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(("MMM DD YYYY HH:mm:ss")) : ""}</MDTypography> } },
            { Header: "Uploaded By", accessor: "createdByUser__name", dataType: "textbox", Cell: ({ cell: { value } }) => { if (!value) { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={"Interface"} size="xs" sx={{ mr: .75 }} />Interface</MDTypography> } else { return <MDTypography variant="caption" color="dark" display="flex" alignItems="center" justifyContent="flex-start" ><MDAvatar name={value} size="xs" sx={{ mr: .75 }} />{value}</MDTypography> } } },
        ]
    else
        columns = [
            { Header: "Expense Type", accessor: "expenseType__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Code", accessor: "account__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Account Name", accessor: "account__name", dataType: "string", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Code", accessor: "costCentre__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Center Name", accessor: "costCentre__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Code", accessor: "vendor__code", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Vendor Name", accessor: "vendor__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Cost Pool", accessor: "costPool__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" >{value}</MDTypography> } },
            { Header: "Sub Cost Pool", accessor: "subCostPool__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            { Header: "Tower", accessor: "tower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: "Sub Tower", accessor: "subTower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
            { Header: typeFilter, accessor: "amount", dataType: "currency", disableFilters: false, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
        ]

    const sortColumn = [
        { Header: "Priority", accessor: "priority", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> }},
        { Header: "Filter", accessor: "filterCondition", disableFilters: true, Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><Icon sx={{ px: 0, m: 0 }} fontSize="small" color="text">info</Icon></Tooltip> : null } },
        { Header: "Tower", accessor: "tower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
        { Header: "Sub Tower", accessor: "subTower__name", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
        { Header: "Portion", accessor: "portion", disableFilters: true, align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{numeral((value) * 100.0).format('0.00')}%</MDTypography> } },
        { Header: "Rule Name", accessor: "ruleName", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
        { Header: "Tier", accessor: "tier", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark" fontWeight="medium">{value}</MDTypography> } },
        { Header: "Condition", accessor: "condtionstr", disableFilters: true, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return (value || "") !== "" ? <Tooltip sx={{ px: 0, m: 0 }} placement="top" title={value}><Icon sx={{ px: 0, m: 0 }} fontSize="small" color="text">info</Icon></Tooltip> : null } },
        { Header: "Destination", accessor: "destinationTable", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Weight", accessor: "weight", dataType: "textbox", disableFilters: false, hidden: !enableAssetDistribution, Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
        { Header: "Source", accessor: "source", dataType: "textbox", disableFilters: false, Cell: ({ cell: { value } }) => { return (<MDTypography variant="caption" color="dark">{value}</MDTypography>) } },
    ]
    if (loading) return <YASkeleton variant="loading" />

    if (loading === false && uploadedFiles === null) {
        return (
            <div>
                no data
            </div>
        );
    }
    if (_err)
        console.error(_err)
    const rows = uploadedFiles.map((row) => {
        let r = {};
        row.id = `${row.id}`
        Object.keys(row).forEach((k) => {
            r[k.replace(/\./g, "__")] = row[k];
        });
        return r;
    });


    let GLPopUp = (props) => {
        const [glRows, setGLRows] = useState([]);
        if (typeFilter == "Spend") {
            var getDetails = async (e, row) => {
                if (e.target.innerHTML !== "" && e.target.innerHTML !== "delete" && rows.length > 0) {
                    row.original.mappingType = 1;
                    row.original.year = yearFilter;
                    row.original.month = monthFilter;
                    //   row.original.mapping = 'costpool';
                    row.original.view = true;
                    row.original.type = 'tower';
                    row.original.newMethod = true;
                    let [err, data] = await fetchRequest.post(`/api/dataflow/getGLData/`, JSON.stringify(row.original));
                    if (err) {
                        console.error('err', err)
                        // handleError(err);
                    }
                    else {
                        let newData = data.map(item => {
                            return {
                                "accountCode": item["account.code"],
                                "accountName": item["account.name"],
                                "expenseType": item["expenseType.name"],
                                "costCentreCode": item["costCentre.code"],
                                "costCentreName": item["costCentre.name"],
                                "vendorCode": item["vendor.code"],
                                "vendorName": item["vendor.name"],
                                "rgtModel": item["rgtModel"],
                                "costType": item["costType"],
                                "amount": item["amount"],
                                "applicationID": item["applicationID"],
                                "invoiceNumber": item["invoiceNumber"],
                                "journalID": item["journalID"],
                                "journalLine": item["journalLine"],
                                "projectID": item["projectID"],
                                "transactionDate": item["transactionDate"],
                                "customString1": item["customString1"],
                                "customString2": item["customString2"],
                                "customString3": item["customString3"],
                                "customString4": item["customString4"],
                                "customString5": item["customString5"],
                                "customDate1": item["customDate1"],
                                "customDate2": item["customDate2"],
                                "customDate3": item["customDate3"],
                                "customDate4": item["customDate4"],
                                "customDate5": item["customDate5"],
                                "customNumber1": item["customNumber1"],
                                "customNumber2": item["customNumber2"],
                                "customNumber3": item["customNumber3"],
                                "customNumber4": item["customNumber4"],
                                "customNumber5": item["customNumber5"],
                            }
                        });
                        setGLRows(newData)
                    }
                }
            };
            useEffect(() => {
                getDetails(props.e, props.row)
            }, [props])
            var glColumns = [
                { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Account Name", accessor: "accountName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Cost Center Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Cost Center Name", accessor: "costCentreName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
                { Header: "Journal ID", accessor: "journalID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Journal Line", accessor: "journalLine", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Application ID", accessor: "applicationID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Transaction Date", accessor: "transactionDate", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value ? moment(value).format(defaultDateFormat || "DD/MM/YYYY") : ""}</MDTypography> } },
                { Header: "Invoice Number", accessor: "invoiceNumber", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "RGT Model", accessor: "rgtModel", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Cost Type", accessor: "costType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 1", accessor: "customString1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 2", accessor: "customString2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 3", accessor: "customString3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 4", accessor: "customString4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 5", accessor: "customString5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 1", accessor: "customDate1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 2", accessor: "customDate2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 3", accessor: "customDate3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 4", accessor: "customDate4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 5", accessor: "customDate5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 1", accessor: "customNumber1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 2", accessor: "customNumber2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 3", accessor: "customNumber3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 4", accessor: "customNumber4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 5", accessor: "customNumber5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            ]
        }
        if (typeFilter == "Budget") {
            getDetails = async (e, row) => {
                if (e.target.innerHTML !== "" && e.target.innerHTML !== "delete" && rows.length > 0) {
                    row.original.mappingType = 1;
                    row.original.year = yearFilter;
                    row.original.month = monthFilter;
                    //   row.original.mapping = 'costpool';
                    row.original.view = true;
                    row.original.type = 'tower';
                    let [err, data] = await fetchRequest.post(`/api/dataflow/getGLDataBudget/`, JSON.stringify(row.original));
                    if (err) {
                        console.error('err', err)
                        // handleError(err);
                    }
                    else {
                        let newData = data.map(item => {
                            return {
                                "accountCode": item["account.code"],
                                "accountName": item["account.name"],
                                "expenseType": item["expenseType.name"],
                                "costCentreCode": item["costCentre.code"],
                                "costCentreName": item["costCentre.name"],
                                "vendorCode": item["vendor.code"],
                                "vendorName": item["vendor.name"],
                                "amount": item["amount"],
                                "projectID": item["projectID"],
                            }
                        });
                        setGLRows(newData)
                    }
                }
            };
            useEffect(() => {
                getDetails(props.e, props.row)
            }, [props])
            glColumns = [
                { Header: "Account Code", accessor: "accountCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Account Name", accessor: "accountName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Expense Type", accessor: "expenseType", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Cost Center Code", accessor: "costCentreCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Cost Center Name", accessor: "costCentreName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Vendor Code", accessor: "vendorCode", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Vendor Name", accessor: "vendorName", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Amount", accessor: "amount", align: "right", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{formatCurrencyNumeral(normalizeCurrency(value), systemCurrencyDetails)}</MDTypography> } },
                { Header: "Project ID", accessor: "projectID", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 1", accessor: "customString1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 2", accessor: "customString2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 3", accessor: "customString3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 4", accessor: "customString4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom String 5", accessor: "customString5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 1", accessor: "customDate1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 2", accessor: "customDate2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 3", accessor: "customDate3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 4", accessor: "customDate4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Date 5", accessor: "customDate5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 1", accessor: "customNumber1", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 2", accessor: "customNumber2", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 3", accessor: "customNumber3", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 4", accessor: "customNumber4", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
                { Header: "Custom Number 5", accessor: "customNumber5", Cell: ({ cell: { value } }) => { return <MDTypography variant="caption" color="dark">{value}</MDTypography> } },
            ]
        }

        return (
            <>
                <MDBox
                    pb={3}
                    minWidth="800px"
                    width="100%" >
                    <DataTable
                        variant="tile"
                        table={{ columns: glColumns, rows: glRows }}
                        containerMaxHeight={424}
                        showTotalEntries={true}
                        isSorted={true}
                        newStyle1={true}
                        noEndBorder
                        entriesPerPage={true}
                        canSearch={true}
                    ></DataTable>
                </MDBox>
            </>
        )
    }

    let getGLDetails = (e, row, columns) => {
        if (
            e.target.innerHTML !== "" &&
            e.target.innerHTML !== "delete" &&
            rows.length > 0
        )
            showCustomForm(
                "General Ledger Transactions",
                () => <GLPopUp e={e} row={row} columns={columns} />,
                null,
                null,
                null,
                '1080px'
            );
    };

    const handleSort = () => {
        handleSortPopup()
    };
    
    const handleSortPopup = () => {
        showCustomForm(
            "Sort Rules",
            () => (<GetSortPopup columns={sortColumn} rows={rows.filter((item) => item.srlNo === 1)} reloadData={reloadData} yearFilter={yearFilter} monthFilter={monthFilter} destinationTable={"towerMapping"} maxWidth={"lg"}/>),
            null,
            null,
            null,
            'lg'
        );
    }

    const handleAction = () => {
        setAction('choose')
    }

    const handleDownload = () => {
        download(yearFilter, monthFilter, yearFilterName, monthFilterName, typeFilter)
    }

    const handleDeleteAll = () => {
        showPrompt("Delete", "Are you sure you want to delete " + 'tower' + " rule for - [ " + monthFilterName + " " + yearFilterName + " ]", async () => {
          let [err, data] = await fetchRequest.delete(`/api/dataflow/towerRules/${yearFilter}/${monthFilter}`)
          if (err) {
            if (err.data?.message) {
              showSnackbar(err.data?.message, "error")
              setRefresh(Math.random());
              reloadData()
            } else {
              console.error(err)
              showAlert("Delete", "Something went wrong. Contact your administrator.");
            }
          }
          else if (data) {
            showSnackbar(data, "success")
            setRefresh(Math.random());
            reloadData()
          }
        });
      }

      const getMenuActions = () => {
        let actions = [];
        if (typeFilter === "Spend") {
            actions.push({ label: "Add", onClick: handleAction, disabled: chargeBack })
            if (rows.length > 0) 
                actions.push({ label: "Sort", onClick: handleSort, disabled: chargeBack })
            actions.push({ label: "Delete", onClick: handleDeleteAll, disabled: chargeBack })
        }
        actions.push({ label: "Download", onClick: handleDownload })
        return actions;
    }
    
    return (
        <>
            {/* <Modal open={dialogOpen} onClose={handleDialogOpen}>
                <MDBox p={3} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Card sx={{ height: "75%", width: "95%", overflow: 'hidden' }}>
                        <MDBox px={3} pt={2} display="flex" justifyContent="space-between" alignItems="center">
                            <MDBox>
                                <MDTypography variant="h6" component="span" color="text">
                                    General Ledger Transactions
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex">
                                <IconButton onClick={handleDialogOpen} title="Close">
                                    <Icon>close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                        <DataTable
                            variant="tile"
                            table={{ columns: glColumns, rows: glRows }}
                            containerMaxHeight={424}
                            showTotalEntries={true}
                            isSorted={true}
                            newStyle1={true}
                            noEndBorder
                            entriesPerPage={true}
                            canSearch={true}

                        >
                        </DataTable>
                    </Card>
                </MDBox>
            </Modal> */}
            <DataTable
                variant="tile"
                table={{ columns, rows }}
                containerMaxHeight={containerHeight}
                showTotalEntries={true}
                isSelectable={overallocation || chargeBack || typeFilter === "Budget" ? false : true}
                isSorted={true}
                newStyle1={true}
                noEndBorder
                onRowClick={typeFilter === "Spend" && getGLDetails}
                entriesPerPage={true}
                canSearch={true}
                filtersState={filtersState}
                onFiltersStateUpdate={handleOnFiltersStateUpdate}
                menuActionRender={!popup ? getMenuActions() : null}
                canFilter={true}
                deleteMultiple={!chargeBack}
                onDeleteMultiple={handleDeleteMultiple}
                viewTable={true}
                serverSideDataPaging={serverSideDataPaging}
                pgIndx={pgIndx}
                setPgIndx={setPgIndx}
                onPageOptionsChange={onPageOptionsChange}
                pageSizeVal={pageSize}
                totalNoOfPages={totalNoOfPages}
                totalItems={totalItems}
                // setSrch={setSearch}
                // srch={search}
                handlePageOption={handlePageOption}
            >
            </DataTable>
        </>
    )
}

export default TowerMappingView;