import { createRef, useEffect, useState } from 'react';
import TableRenderer from '../ToggleTableRenderer';
import Highcharts from 'highcharts/highcharts.src.js';
import HighchartsReact from 'highcharts-react-official';
import { useLocation, useNavigate } from "react-router-dom";
import { useResizeDetector } from 'react-resize-detector';
import { getDrilldownPath, removeSessionFilter} from 'utils';
import { providerColorSet, creatToggleTableCubeOptions } from 'utils/charts';
import colors from "assets/theme/base/colors";
import DashboardItem from 'components/DashboardItem';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { useContext } from 'react';
import { CubeContext } from '@cubejs-client/react';
import { useImmer } from 'use-immer';
// import numeral from 'numeral';
import { useYADialog } from "components/YADialog";
import _ from 'lodash';
import { formatCurrencyNumeral } from 'utils';
import { useAppController } from "context";

const PiesChartRenderer = ({ title, subtitle, chartHelpContextKey, vizState, vizOptions, cubeOptions }) => {

    const chartRef = createRef();
    const { width, height, ref: rref } = useResizeDetector();
    const [toggleType, setToggleType] = useState('chart');
    const [ tableVizOptions, setTableVizOptions ] = useState(null)
    const [tableCubeOptions, setTableCubeOptions] = useState(null)
    const [ controller ] = useAppController();
    const { systemCurrencyDetails } = controller;

    let range = [];
    const {showReport} = useYADialog();
    let navigate = useNavigate()
    let location = useLocation()
    let totalValue = 0
    let subCatPercentage = 0
    let restCategoryPercentage = 0
    let totalPercentage = 0
    let totalPrevValue = 0
    let perValue = 0
    let i = 0

    const { query } = vizState;
    const [cubeQuery, setCubeQuery] = useImmer(query);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setCubeQuery(query);
    }, [query]);

    useEffect(async () => {
        let measuresCol = query[0]?.measures.map( measures => {
            let col = {
                "name": measures,
                "displayName": String(measures).match(/[^|]*$/g)[0],
                "type": "currency"
            }
            return col
        })
        let dimensionsCol = query[0]?.dimensions.map( dimensions => {
            let col = {
                "name": dimensions,
                "displayName": String(dimensions).match(/[^|]*$/g)[0],
                "type": "string"
            }
            return col
        })
        var tableVizOptions = Object.assign({}, vizOptions)
        tableVizOptions["columns"] = [...dimensionsCol, ...measuresCol]
        tableVizOptions["params"] = [tableVizOptions['category']]
        tableVizOptions["disableServerSidePaging"] = true;
        tableVizOptions["hideColumnOptions"] = true
        tableVizOptions["heightUnits"] = 5.8;
        setTableVizOptions(tableVizOptions)
        if (cubeOptions) {
            let tableCubeOptions = creatToggleTableCubeOptions(cubeOptions)
            setTableCubeOptions(tableCubeOptions)
        }
    },[vizOptions, vizState, toggleType === 'table'])

    const { cubeApi } = useContext(CubeContext);
    const [resultSet1, setResultSet1] = useState(null);
    const [resultSet2, setResultSet2] = useState(null);
    const [resultSet1All, setResultSet1All] = useState(null);
    const [resultSet2All, setResultSet2All] = useState(null);
    const [resultSetSub, setResultSetSub] = useState([]);
    const [currentFilters, setCurrentFilters] = useState([])

    function modifyQueryBasedOnIgnoreFilters(query, vizOptions) {
        if (vizOptions.ignoreQuery1Filters && vizOptions.ignoreQuery1Filters.length > 0) {
            let newQuery = JSON.parse(JSON.stringify(query));
            vizOptions.ignoreQuery1Filters.forEach(ignoreFilter => {
                newQuery.filters = newQuery.filters.filter(filter => filter.member !== ignoreFilter);
            });
            return newQuery;
        }
        return query;
    }
    useEffect(() => {
        async function getData() {
            if (query.length > 0) {
                let newQuery = modifyQueryBasedOnIgnoreFilters(query[0], vizOptions);
                cubeApi.load(newQuery).then((resultSet) => {
                    setCurrentFilters(removeSessionFilter(resultSet.loadResponse.pivotQuery.filters, vizOptions))
                    setResultSet1(resultSet.tablePivot());
                    setResultSet1All(resultSet)
                });
            } else {
                setResultSet1([])
                setResultSet1All([])
            }

            if (query.length > 1) {
                cubeApi.load(query[1]).then((resultSet) => {
                    setResultSet2(resultSet.tablePivot());
                    setResultSet2All(resultSet)
                });
            } else {
                setResultSet2([])
                setResultSet2All([])
            }
            if (vizOptions.subcategories) {
                let subResults = []
                for (i = 2; i < query.length; i++) {
                    await cubeApi.load(query[i]).then((resultSet) => {
                        subResults.push(resultSet.tablePivot());
                    });
                }
                setResultSetSub(subResults)
            }
            setLoading(false);
        }
        getData(); 
    }, [cubeQuery]);
    
    if (loading || !resultSet1 || !resultSet2)
        return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>
    if (resultSetSub.length > 0) {
        if (loading || !resultSet1 || !resultSet2 || resultSetSub.length === 0)
            return <DashboardItem loading={loading} title={title} subtitle={subtitle}></DashboardItem>
    }
    resultSet1.map((item) => {
        if (vizOptions && vizOptions.series) {
            vizOptions.series.map((col) => {
                range.push({
                    name: col.useNameString ? col.name : item[col.name] ? item[col.name] : '',
                    color: colors.pieColors[i++],
                    y: Number(item[col.value]),
                    percentage: 0
                })
                totalValue = totalValue  +  (col.value1 ?  Number(item[col.value1]) :  Number(item[col.value]))
            })
        }
    });

    resultSet2.map((item) => {
        if (vizOptions && vizOptions.series) {
            vizOptions.series.map((col) => {
                totalPrevValue = totalPrevValue + Number(vizOptions.piesType === "multipleMessure" ? item[col.value2] : item[col.value])
            })
        }
    });

    for (let i = 0; i < range.length; i++) {
        range[i].percentage = Number(((range[i].y / totalValue) * 100).toFixed(0))
    }

    perValue = totalPrevValue === 0 ? 0 : vizOptions.wigetType === "doughnutpercent" ? Math.round((totalPrevValue / totalValue) * 100) : Math.round(((totalValue - totalPrevValue) / totalPrevValue) * 100);

    var obj = [
        {
            name: 'Total Spend',
            color: colors.grey[400],
            y: 100 - perValue,
            percentage: 0
        },
        {
            name: 'Cloud Spend',
            color: colors.pieColors[1],
            y: perValue,
            percentage: 0
        }
    ]

    if (vizOptions.piesType === "multipleMessure") 
        obj = [
        {
            name: vizOptions.label1 || '',
            color: colors.grey[400],
            y: 100 - perValue,
            amount: totalValue - totalPrevValue,
            percentage: 0
        },
        {
            name: vizOptions.label2 || '',
            color: colors.pieColors[1],
            y: perValue,
            amount: totalPrevValue,
            percentage: 0
        }
    ]
    

    if (vizOptions.subcategories) {
        let subcategoryTotal = 0
        resultSetSub.map(item => {
            if (vizOptions && vizOptions.series) {
                vizOptions.series.map((col) => {
                    subcategoryTotal = subcategoryTotal + Number(item[0][col.value])
                })
            }
            let categoryDiffValue = totalPrevValue - subcategoryTotal
            let totalDiffValue = totalValue - totalPrevValue
            // obj = [
            //     {
            //         name: 'Others',
            //         color: colors.grey[400],
            //         y: Math.round((totalDiffValue / totalValue) * 100),
            //         amount: totalDiffValue,
            //         percentage: 0
            //     },
            //     {
            //         name: 'Cloud',
            //         color: colors.pieColors[1],
            //         y: Math.round((categoryDiffValue / totalValue) * 100),
            //         amount: categoryDiffValue,
            //         percentage: 0
            //     },
            //     {
            //         name: ' Cloud Service Provider',
            //         color: colors.pieColors[2],
            //         y: Math.round((subcategoryTotal / totalValue) * 100),
            //         amount: subcategoryTotal,
            //         percentage: 0
            //     }
            // ]
            subCatPercentage = Math.round((subcategoryTotal / totalValue) * 100)
            restCategoryPercentage =  Math.round((categoryDiffValue / totalValue) * 100)
            totalPercentage = subCatPercentage + restCategoryPercentage
            obj = [
                {
                    name: vizOptions.label1 || '',
                    color: colors.grey[400],
                    y: Math.round((totalDiffValue / totalValue) * 100),
                    amount: totalDiffValue,
                    percentage: 0
                },
                {
                    name: vizOptions.label3 || '',
                    color: colors.pieColors[1],
                    y: Math.round((subcategoryTotal / totalValue) * 100),
                    amount: subcategoryTotal,
                    percentage: 0
                },
                {
                    name: vizOptions.label2 || '',
                    color: colors.pieColors[2],
                    y: Math.round((categoryDiffValue / totalValue) * 100),
                    amount: categoryDiffValue,
                    percentage: 0
                }
            ]

        })

    }

    range = vizOptions.wigetType === "doughnutpercent" ? obj : range
    var textColour = (totalValue > totalPrevValue ? "red" : "green");
    // var perText = (textColour === 'green' ? ' ▼ ' : textColour === 'red' ? ' ▲ ' : '') + '' + (Math.abs(perValue) + '% ' + (textColour === 'green' ? ' down ' : textColour === 'red' ? ' up ' : '') )
    var perText = vizOptions.wigetType === "doughnutpercent" ? (Math.abs(perValue) + '%') : (textColour === 'green' ? ' ▼ ' : textColour === 'red' ? ' ▲ ' : '') + '' + (Math.abs(perValue) + '% ')
    var withText = (textColour === 'green' ? ' down from last month' : textColour === 'red' ? ' up from last month' : '')

    const totWidthScreen = window.innerWidth
    var titleSize, subtitleSize, ySize

    // if (totWidthScreen <= 360) { titleSize = 15; subtitleSize = 8 }
    // else if (totWidthScreen <= 450 && totWidthScreen > 360) { titleSize = 19; subtitleSize = 15 }
    // else if (totWidthScreen <= 660 && totWidthScreen > 450) { titleSize = 20; subtitleSize = 15 }
    // else if (totWidthScreen <= 1024 && totWidthScreen > 660) { titleSize = 20; subtitleSize = 14 }
    // else if (totWidthScreen <= 1090 && totWidthScreen > 1024) { titleSize = 18; subtitleSize = 10 }
    // else if (totWidthScreen <= 1280 && totWidthScreen > 1090) { titleSize = 20; subtitleSize = 12 }
    // else if (totWidthScreen <= 1400 && totWidthScreen > 1280) { titleSize = 20; subtitleSize = 15 }
    // else if (totWidthScreen > 1400) { titleSize = 20; subtitleSize = 15 }

    if (totWidthScreen <= 360) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 20 : 15; subtitleSize = vizOptions.wigetType === "doughnutpercent" ? 10 : 8; ySize = 20 }
    else if (totWidthScreen <= 450 && totWidthScreen > 360) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 30 : 19; subtitleSize = 15; ySize = 26 }
    else if (totWidthScreen <= 660 && totWidthScreen > 450) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 15; ySize = 26 }
    else if (totWidthScreen <= 1024 && totWidthScreen > 660) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 14; ySize = 32 }
    else if (totWidthScreen <= 1090 && totWidthScreen > 1024) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 30 : 18; subtitleSize = 10; ySize = 32 }
    else if (totWidthScreen <= 1280 && totWidthScreen > 1090) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 12; ySize = 32 }
    else if (totWidthScreen <= 1400 && totWidthScreen > 1280) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 15; ySize = 32 }
    else if (totWidthScreen > 1400) { titleSize = vizOptions.wigetType === "doughnutpercent" ? 36 : 20; subtitleSize = 15; ySize = 32 }



    if (vizOptions.providerview)
        range.forEach((item) => {
            item.color = providerColorSet(item.name, 0, colors.providerColors)
        })

    var opts = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            width: width,
            height: vizOptions.wigetType === "doughnutpercent" ? height : height * 0.945,
            style: { fontFamily: 'inherit', paddingTop: '0', fontSize: '20px', color: "#9EAEE5" }
        },
        legend: {
            itemStyle: { fontFamily: 'inherit', fontWeight: 500 },
        },
        title: vizOptions.showTitle ?
            // {
            //     text: vizOptions.wigetType === "doughnutpercent" ? vizOptions.subcategories ? '<span style="font-size: ' + subtitleSize + 'px;">Total Cloud Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalPrevValue.toFixed(2)).format('$0,0') + '</span></b>' :
            //         '<span style="font-weight: bold; font-size: 25px;"><br>' + perText + '</span>'
            //         :
            //         '<span style="font-size: ' + titleSize + 'px;">Total Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + numeral(totalValue.toFixed(2)).format('$0,0') + '</span></b><br/><span style="font-size: ' + subtitleSize + 'px;"><span style="color:' + textColour + '">' + perText + '</span><br/>' + withText + '</span>',
            //     align: 'center',
            //     verticalAlign: 'middle',
            //     y: 12
            // }
            {
                text: vizOptions.wigetType === "doughnutpercent" ? vizOptions.subcategories ? ' <span style="font-size: ' + subtitleSize + 'px;">'+"  "+'</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + totalPercentage + '%' + '</span></b>' :
                    '<span style="font-weight: bold; font-size: 25px;"><br>&nbsp;' + perText + '</span>'
                    :
                    vizOptions.recommendation?'<span style="font-size: ' + titleSize + 'px;">Total Saving</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + formatCurrencyNumeral(totalValue.toFixed(2), systemCurrencyDetails) + '</span></b><br/><span style="font-size: ': vizOptions.disableComparison ? '<span style="font-size: ' + titleSize + 'px;">Total Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + formatCurrencyNumeral(totalValue.toFixed(2), systemCurrencyDetails) + '</span></b><br/><span style="font-size: ': vizOptions.disableComparison ? '<span style="font-size: ' + titleSize + 'px;">Total Saving</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + formatCurrencyNumeral(totalValue.toFixed(2), systemCurrencyDetails) + '</span></b><br/><span style="font-size: ' : '<span style="font-size: ' + titleSize + 'px;">Total Spend</span><br> <b><span style="font-size: ' + titleSize + 'px;">' + formatCurrencyNumeral(totalValue.toFixed(2), systemCurrencyDetails)+ '</span></b><br/><span style="font-size: ' + subtitleSize + 'px;"><span style="color:' + textColour + '">' + perText + '</span><br/>' + withText + '</span>',
                align: 'center',
                verticalAlign: 'middle',
                y: vizOptions.wigetType === "doughnutpercent" ? ySize : 12
            }
            : '',
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: vizOptions.wigetType === "doughnutpercent" ? false : true,
                cursor: (vizOptions["popupTo"] || vizOptions["drillTo"]) ? "pointer" : "default",
                innerSize: vizOptions.wigetType === "doughnut" ? '80%' : vizOptions.wigetType === "doughnutpercent" ? '60%' : '0',
                center: ['50%', '50%'],
                size: vizOptions.showTitle ? '60%' : '50%',
                dataLabels: {
                    // enabled: vizOptions.wigetType === "doughnutpercent" ? false : true,
                    enabled: true,
                    style: {
                        fontSize: vizOptions.title ? '15px' : '11px',
                        fontFamily: 'inherit',
                        fontWeight: 400,
                    },
                    // distance: -40,
                    // formatter: function () {
                    //     return `${this.point.name}<br><b>${Highcharts.numberFormat(this.point.percentage, 0)}%</b>&nbsp;($${Highcharts.numberFormat(this.point.y, 0, ".", ",")})`;
                    // }
                    formatter: vizOptions.wigetType === "doughnutpercent" ? function () {
                        return '<b>' + this.point.name + '</b>' + `: ${systemCurrencyDetails?.code || '$'}` + Highcharts.numberFormat(this.point.amount, 0, ".", ",") + ` (${Highcharts.numberFormat(this.point.percentage, 0)}%)`;
                    } :
                        function () {
                        return `${this.point.name}<br><b>${Highcharts.numberFormat(this.point.percentage, 0)}%</b>&nbsp;(${systemCurrencyDetails?.code || '$'}${Highcharts.numberFormat(this.point.y, 0, ".", ",")})`;
                    }
                },
                point: {
                    showInLegend: true,
                    cumulative: 100,
                    events: {
                        click: function (event) {
                            var obj = Object.assign([], [...currentFilters]);
                            if (vizOptions.category)
                                // if (!obj.find((({name}) => name === vizOptions.category))) 
                                if (obj.find((({ name }) => name === vizOptions.category))) {
                                    _.remove(obj, { name: vizOptions.category })
                                    obj.push({ "name": vizOptions.category, "values": [event.point.name] })
                                }
                                else {
                                    obj.push({ "name": vizOptions.category, "values": [event.point.name] })
                                }
                            if (vizOptions.excludeFilters && vizOptions.excludeFilters.length > 0) {
                                vizOptions.excludeFilters.map((fil) => {
                                    if (obj.find((({ name }) => name === fil)))
                                        _.remove(obj, { name: fil })
                                })
                            }
                            vizOptions["drillTo"] && vizOptions["drillTo"] !== "" && navigate(location.pathname === "/" ? vizOptions.drillTo : getDrilldownPath(location.pathname, vizOptions.drillTo), { state: obj })
                            vizOptions["popupTo"] && vizOptions["popupTo"] !== "" && showReport(vizOptions["popupTo"], obj, null);

                        }
                    }
                },
            },
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        enabled: vizOptions.wigetType === "doughnutpercent" ? false : true
                    }
                }
            }
        },
        tooltip: {
            enabled: vizOptions.wigetType === "doughnutpercent" ? true : true,
            outside: false,
            formatter: vizOptions.wigetType === "doughnutpercent" ? vizOptions.subcategories ? function () {
                return '<b>' + this.point.name + '</b>' + `: ${systemCurrencyDetails?.code || '$'}` + Highcharts.numberFormat(this.point.amount, 0, ".", ",") + ` (${this.point.y}%)`;
            } :
                function () {
                    return '<b>' + this.point.name + '</b>' + ': ' + this.point.y + '%';
                }
                :
                function () {
                    return '<b>' + this.point.name + '</b>' + `: ${systemCurrencyDetails?.code || '$'}` + Highcharts.numberFormat(this.point.y, 0, ".", ",");
                }
        },
        series: [{
            name: 'Spend',
            // colorByPoint: true,
            data: range,
        }]
    }
    let navigateToPage = (linkTo) => {
        linkTo && linkTo !== "" && navigate(location.pathname === "/" ? linkTo : getDrilldownPath(location.pathname, linkTo), { state: {} })
    }
    const nodata = vizOptions.wigetType === "doughnutpercent" ? totalValue === 0 && totalPrevValue == 0 ? true: false : range.length === 0;
    
    return (
        toggleType === 'table' && tableVizOptions ?
        <TableRenderer title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} vizState={vizState} vizOptions={tableVizOptions} toggleType={toggleType} setToggleType={setToggleType} resultSet={resultSet1All} resultSet2={resultSet2All} cubeOptions={tableCubeOptions}/> :
         <DashboardItem nodata={nodata} hideToggle={true} title={title} subtitle={subtitle} chartHelpContextKey={chartHelpContextKey} chartRef={chartRef} toggleType={toggleType} setToggleType={setToggleType} parsedResultset={resultSet1All?.tablePivot()} cubeOptions={cubeOptions}>
            <div ref={rref} style={{ position: 'relative', height: '100%' }}>
                <div style={{ position: 'absolute', left: 0, top: -10, bottom: 0, right: 0 }}>
                    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={opts} />
                    {vizOptions["linkTo"] && vizOptions["linkTo"] !== "" &&
                        <MDBox display="flex" pt={1.5} flexDirection="row" justifyContent="flex-end">
                            <MDTypography style={{ position: 'absolute', bottom: '-10px', right: '5px' }} variant="button" px={0.5} py={0.5} fontWeight="medium" whiteSpace="nowrap" sx={{ "&:hover": { cursor: 'pointer', backgroundColor: colors.linkBackColour ? colors.linkBackColour : "light" }, color: colors.linkColour, borderRadius: "5px" }} color={colors.linkColour ? colors.linkColour : "dark"} onClick={() => { navigateToPage(vizOptions["linkTo"]) }}>
                                {vizOptions.linkText.toUpperCase()}&nbsp;<Icon sx={{ pt: 0.25 }} variant="contained">east</Icon>
                            </MDTypography>
                        </MDBox>
                    }
                </div>
            </div>
        </DashboardItem>
    )
}

export default PiesChartRenderer;